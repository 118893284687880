<template>
  <div class="app-container">
    <!--    搜索区域-->
    <el-card class="filter-container" shadow="never">
      <div>
        <el-form :inline="true" :model="listQuery" size="small" label-width="140px">
          <el-form-item label="企业名称：">
            <el-input style="width: 230px" v-model="listQuery.keyword" placeholder="请输入" clearable></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div style="margin-left: 55px;">
        <el-button type="primary" size="small" @click="handleSearchList()">查询</el-button>
        <el-button size="small" @click="handleResetSearch()">重置</el-button>
      </div>
    </el-card>
    <!--    功能区域-->
<!--    <div style="margin: 20px 0">
      <el-button type="primary" @click="handleAdd" >添加</el-button>
      <el-button type="danger" @click="deleteBatch" >批量删除</el-button>
    </div>-->
    <!--    表格区域-->
    <div class="table-container">
      <el-table ref="companyInfoTable"
                :data="tableData"
                style="width: 100%;"
                @selection-change="handleSelectionChange"
                v-loading="listLoading" border>
        <el-table-column type="selection" width="60" align="center"></el-table-column>
        <el-table-column label="编号" width="60" align="center">
          <template #default="scope">{{scope.row.id}}</template>
        </el-table-column>
        <el-table-column label="企业名称"  align="center">
          <template #default="scope">{{scope.row.companyName}}</template>
        </el-table-column>
        <el-table-column label="统一社会信用代码" align="center">
          <template #default="scope">{{scope.row.creditCode}}</template>
        </el-table-column>
        <el-table-column label="企业法人" align="center">
          <template #default="scope">{{scope.row.enterpriseLegalPerson}}</template>
        </el-table-column>
        <el-table-column label="管辖环保局" align="center">
          <template #default="scope">{{scope.row.responsibleProtectionBureau}}</template>
        </el-table-column>
        <el-table-column label="环保负责人" align="center">
          <template #default="scope">{{scope.row.superintendent}}</template>
        </el-table-column>
        <el-table-column label="联系电话" align="center">
          <template #default="scope">{{scope.row.telephone}}</template>
        </el-table-column>
        <el-table-column label="操作" width="120" align="center">
          <template #default="scope">
            <el-button size="mini" type="primary" plain @click="handleUpdate(scope.row)">查看明细</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!--    分页-->
    <div style="margin: 10px 0">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes,prev, pager, next,jumper"
          :page-size="listQuery.pageSize"
          :page-sizes="[5,10,15,20,50,100,200,500,1000]"
          :current-page.sync="listQuery.pageNum"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog
        :title="isEditFlag == true?'详情':'添加'"
        v-model="dialogVisible"
        :close-on-click-modal="false"
        width="60%">
      <el-form :model="companyInfoModel"
               :rules="rules"
               ref="companyInfoFrom"
               label-width="150px"
               size="small">
        <el-row>
          <el-col :span="12">
            <el-form-item label="企业名称：" >
              <el-input v-model="companyInfoModel.companyName" style="width: 80%" v-bind:readonly="isReadOnly" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="统一社会信用代码：" >
              <el-input v-model="companyInfoModel.creditCode" style="width: 80%" v-bind:readonly="isReadOnly"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="工商注册详细地址：" >
              <el-input v-model="companyInfoModel.businessRegistrationDetailedAddress" style="width: 80%" v-bind:readonly="isReadOnly"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="工商所在地：" >
              <el-input v-model="companyInfoModel.businessLocation" style="width: 80%" v-bind:readonly="isReadOnly"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="生产设施地址：" >
              <el-input v-model="companyInfoModel.productionFacilityAddress" style="width: 80%" v-bind:readonly="isReadOnly"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="企业法人：" >
              <el-input v-model="companyInfoModel.enterpriseLegalPerson" style="width: 80%" v-bind:readonly="isReadOnly"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="年产危废规模：" >
              <el-input v-model="companyInfoModel.annualProductionScale" style="width: 80%" v-bind:readonly="isReadOnly"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="管辖环保局：" >
              <el-input v-model="companyInfoModel.responsibleProtectionBureau" style="width: 80%" v-bind:readonly="isReadOnly"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="注册资金（万元）：" >
              <el-input v-model="companyInfoModel.registeredCapital" style="width: 80%" v-bind:readonly="isReadOnly"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="面积（万平方）：" >
              <el-input v-model="companyInfoModel.area" style="width: 80%" v-bind:readonly="isReadOnly"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="环保负责人：" >
              <el-input v-model="companyInfoModel.superintendent" style="width: 80%" v-bind:readonly="isReadOnly"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话：" >
              <el-input v-model="companyInfoModel.telephone" style="width: 80%" v-bind:readonly="isReadOnly"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="传真：" >
              <el-input v-model="companyInfoModel.fax" style="width: 80%" v-bind:readonly="isReadOnly"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="邮箱：" >
              <el-input v-model="companyInfoModel.email" style="width: 80%" v-bind:readonly="isReadOnly"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="营业执照：" v-if="!isEditFlag">
              <el-upload ref="uploadForm" v-model="companyInfoModel.businessLicense" drag
                         action="#"
                         :file-list="fileListShow"
                         :auto-upload="false"
                         :before-remove="handleRemove"
                         :on-change="fileChange"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="执照预览：" v-if="!isEditFlag">
              <img :src="previewPath" alt="" style="width:80%;"  @click="handlePictureEnlarge(previewPath)"   />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" v-if="isEditFlag" >
            <el-form-item  label="执照预览：">
              <img style="width: 80%" :src="companyInfoModel.businessLicense"  class="avatar"  @click="handlePictureEnlarge(companyInfoModel.businessLicense)"  >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisible = false">关 闭</el-button>
          </span>
      </template>
    </el-dialog>

    <el-dialog v-model="dialogVisiblePic" title="营业执照">
      <img :src="enlargedImageUrl" alt="营业执照" style="max-width: 100%; display: block; margin: auto;">
    </el-dialog>

  </div>


</template>

<script>

import {
  batchDeleteCompanyInfo,
  deleteCompanyInfo,
  companyInfoList,
  updateCompanyInfo,
  createCompanyInfo,
} from "@/api/companyInfo";

const defaultListQuery = {
  pageNum: 1,
  pageSize: 5,
  keyword: null,
  judge: "all",
};
const defaultCompanyInfo = {
  companyName: null,
  creditCode: null,
  businessRegistrationDetailedAddress: null,
  businessLocation: null,
  productionFacilityAddress: null,
  enterpriseLegalPerson: null,
  businessLicense: null,
  editBusinessLicense: null,
  annualProductionScale: null,
  responsibleProtectionBureau: null,
  registeredCapital: null,
  area: null,
  superintendent: null,
  telephone: null,
  fax: null,
  email: null,
};


export default {
  name: 'companyInfo',
  components: {},
  data() {
    return {
      listQuery: Object.assign({}, defaultListQuery),
      tableData: [],
      total: null,
      listLoading: false,
      ids: [],
      rules: {
        username: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
        ],
      },
      dialogVisible: false,
      isEditFlag: false,
      companyInfoModel: Object.assign({}, defaultCompanyInfo),

      //营业执照
      fileListShow:[],
      fileList:[],
      previewPath: '', // 预览路径
      dialogVisiblePic: false,
      enlargedImageUrl: '',
      //营业执照

      isReadOnly:true,

    }
  },
  created() {
    this.getTableList()
  },
  methods: {
    //放大预览
    handlePictureEnlarge(imageUrl) {
      this.enlargedImageUrl = imageUrl;
      this.dialogVisiblePic = true;
    },
    //文件上传
    fileChange(file, fileList) {
      this.fileList = [];
      this.fileList.push(file);
      this.fileListShow = [];
      this.fileListShow.push({
        name: file["name"],
      })
      //如果上传的图片没有url字段，手动生成
      if (!file.url) {
        this.previewPath =  URL.createObjectURL(file.raw);
      }
    },
    //文件删除
    handleRemove(file, fileList) {
      // 通过file.name或者其他属性来匹配需要删除的文件
      this.fileList = this.fileList.filter(item => item.name !== file.name);
    },
    //重置
    handleResetSearch() {
      this.listQuery = Object.assign({}, defaultListQuery);
      this.getTableList();
    },
    //查询
    handleSearchList() {
      this.listQuery.pageNum = 1;
      this.getTableList();
    },
    //多选
    handleSelectionChange(val){
      this.ids = val.map(v => v.id);
    },
    // 改变当前每页的个数触发
    handleSizeChange(val) {
      this.listQuery.pageNum = 1;
      this.listQuery.pageSize = val;
      this.getTableList();
    },
    // 改变当前页码触发
    handleCurrentChange(val) {
      this.listQuery.pageNum = val;
      this.getTableList();
    },
    //删除营业执照
    handleDeleteLicense(){
      this.$confirm('确定清空已传营业执照吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        //删除附件
        this.companyInfoModel.editBusinessLicense = this.companyInfoModel.businessLicense;
        this.companyInfoModel.businessLicense = "";
        this.$forceUpdate();   // 强制更新
      });
    },
    //删除
    handleDelete(row){
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteCompanyInfo(row.id).then(res => {
          if (res.code === '0') {
            /*this.$message({
              type: "success",
              message: "删除成功"
            })*/
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.getTableList();
        });
      });
    },
    //批量删除
    deleteBatch() {
      if (!this.ids.length) {
        //this.$message.warning("请选择数据！")
        return
      }
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        batchDeleteCompanyInfo(this.ids).then(res => {
          if (res.code === '0') {
            /*this.$message({
              type: "success",
              message: "批量删除成功"
            })*/
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.getTableList();
        });
      });
    },
    //列表
    getTableList() {
      this.listLoading = true;
      companyInfoList(this.listQuery).then(res => {
        if (res.code === '0') {
              this.listLoading = false;
              this.tableData = res.data.records;
              this.total = res.data.total;
        }
      })
    },
    //添加路由
    handleAdd() {
      this.dialogVisible = true;
      this.isEditFlag = false;
      this.companyInfoModel = Object.assign({},defaultCompanyInfo);

      this.$nextTick(() => {
        if (this.$refs['uploadForm']) {
          this.$refs['uploadForm'].clearFiles()  // 清除历史文件列表
        }
      })
      this.fileListShow=[];
      this.fileList=[];
      this.previewPath=''; // 预览路径
      this.dialogVisiblePic= false;
      this.enlargedImageUrl= '';
    },
    //修改路由
    handleUpdate(row) {
      this.dialogVisible = true;
      this.isEditFlag = true;
      this.companyInfoModel = Object.assign({},row);

      this.$nextTick(() => {
        if (this.$refs['uploadForm']) {
          this.$refs['uploadForm'].clearFiles()  // 清除历史文件列表
        }
      })
      this.fileListShow=[];
      this.fileList=[];
      this.previewPath=''; // 预览路径
      this.dialogVisiblePic= false;
      this.enlargedImageUrl= '';
    },
    //确认操作
    handleDialogConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('是否提交数据', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {

            let formData = new FormData();
            for (let key in this.companyInfoModel) {
              formData.append(key, this.companyInfoModel[key]);
            }

            for (let i = 0; i < this.fileList.length; i++) {
              formData.append('myFile', this.fileList[i].raw);
            }

            if (this.isEditFlag) {
              updateCompanyInfo(formData).then(res => {
                if (res.code === '0') {
                  /*this.$message({
                    message: '修改成功',
                    type: 'success',
                  });*/
                }else {
                  this.$message({
                    type: "error",
                    message: res.msg
                  })
                }
                this.dialogVisible =false;
                this.getTableList();
              });

            }else {
              createCompanyInfo(formData).then(res => {
                if (res.code === '0') {
                  /*this.$message({
                    message: '提交成功',
                    type: 'success',
                  });*/
                }else {
                  this.$message({
                    type: "error",
                    message: res.msg
                  })
                }
                this.dialogVisible =false;
                this.getTableList();
              });
            }
          });
        }
      });
    },

  }
}
</script>
